import qs from 'qs';

import BaseService from '~/services/BaseService';

import { type ApiResponse, type ApiResponseInner, type PaginationOptions } from '~/types/global';

import type {
  CommunitySearchDocument,
  PopularSearchItem,
  RequestSearchCommunities,
  ResponseSearchCommunitiesContentsCount,
  SearchOrderType,
} from './type';

class SearchService extends BaseService {
  constructor() {
    super('/search');
  }

  /** 인기검색어 조회 */
  fetchSearchPopularKeywords(pagingForm: PaginationOptions) {
    return this.get<ApiResponseInner<PopularSearchItem[]>>('/popular-keywords', { params: pagingForm });
  }

  /** 커뮤니티 게시판 리스트와 카운트 조회 */
  fetchSearchCommunitiesContentsCount(keyword: string) {
    return this.get<ApiResponseInner<ResponseSearchCommunitiesContentsCount>>('/communities/contents-counts', {
      params: { keyword },
    });
  }

  /** 커뮤니티 게시판별 검색 결과 */
  fetchSearchCommunities(params: RequestSearchCommunities) {
    return this.get<{
      items: CommunitySearchDocument[];
      paging: { totalCount: number; pageSize: number; orderType: SearchOrderType } & (
        | { pivotId: number; pivotScore: number }
        | { page: number }
      );
    }>('/communities', {
      params,
    });
  }

  /** 커뮤니티 검색가능 게시판 */
  fetchSearchableCommunityBoards() {
    return this.getV2<ApiResponse<{ items: { boardId: number; boardName: string }[] }>>(
      '/communities/searchable-boards',
    );
  }
}

export const searchService = new SearchService();
