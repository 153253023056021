import type { CommunitySearchType } from '~/services/community/constant';
import { type BoardContentLevel, type BoardCount } from '~/services/community/type';

/** 인기검색어 순위 변동 */
export enum PopularSearchChangedType {
  Up = 'UP',
  Down = 'DOWN',
  New = 'NEW',
  NotChanged = 'NOT_CHANGED',
}

/** 검색 정렬 순서 타입 */
export enum SearchOrderType {
  Accuracy = 'ACCURACY',
  Recentness = 'RECENTNESS',
  Views = 'VIEWS',
}

/** 인기검색어 아이템 */
export interface PopularSearchItem {
  rank: number;
  keyword: string;
  count: number;
  changedType: PopularSearchChangedType;
}

export interface CommunitySearchDocument {
  documentId: number;
  score: number;
  highlight: {
    title: string[];
    content: string[];
    userNickName: string[];
  };
  document: CummunitySearchedBoard;
}

export interface CummunitySearchedBoard {
  id: number;
  title: string;
  boardCategoryCd: string;
  boardCategoryName: string;
  boardId: number;
  boardName: string;
  boardTypeCd: string;
  userId: number;
  userNickName: string;
  userProfileImageUrl: string;
  userIsCreator: boolean;
  userServiceLevel: BoardContentLevel;
  /** 썸네일이 없는 경우 빈 스트링이 올 수 있음 */
  thumbnailImageUrl?: string | '';
  viewCount: number;
  likeCount: number;
  fileCount: number;
  commentCount: number;
  isLiked: boolean;
  createdAt: string;
}

// API 요청, 응답 타입 정의

export interface ResponseSearchCommunitiesContentsCount {
  totalCount: number;
  boardCounts: BoardCount[];
}

export type RequestSearchCommunities = {
  keyword: string;
  orderType: SearchOrderType;
  searchType?: CommunitySearchType;
  /* boardId 값을 보내지 않으면, 전체 게시판을 조회 */
  boardId?: number | null;
  pageSize: number;
} & (
  | {
      /* 무한스크롤 검색을 위한 ID. 1페이지는 이 값을 보내지 않는다. */
      pivotId?: number | null;
      /* 무한스크롤 검색을 위한 Score. 1페이지는 이 값을 보내지 않는다. */
      pivotScore?: number | null;
      pageType?: 'CURSOR';
    }
  | {
      page: number;
      pageType?: 'PAGE';
    }
);
