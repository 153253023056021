const useCommunityBoards = async () => {
  const { data: communityCategories } = await useCommunityCategories();

  return computed(() => {
    if (!communityCategories.value) {
      return [];
    }

    return communityCategories.value.map(({ boards }) => boards).flat();
  });
};

export default useCommunityBoards;
